import type { AuthServiceV3 } from '@oneaudi/audi-auth-service';
import type { EnvConfigServiceV1 } from '@oneaudi/audi-env-config-service';
import type { EventServiceV2 } from '@oneaudi/audi-event-service';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { DealerContextServiceV1 } from '@oneaudi/dealer-context-service';
import type { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import type { AudiHeaderStateServiceInterfaceV2 } from '@oneaudi/header-state-service';
import type { AudiMarketContextServiceV2 } from '@oneaudi/market-context-service';
import type { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useMemo } from 'react';
import type { FeatureServiceDependencies } from '../FeatureHubAppDefinition';

export interface ServiceContextState {
  readonly authService: AuthServiceV3;
  readonly envConfigService: EnvConfigServiceV1;
  readonly dealerContextService: DealerContextServiceV1;
  readonly footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
  readonly headerStateService: AudiHeaderStateServiceInterfaceV2;
  readonly layerManager: LayerManagerV25;
  readonly trackingService: TrackingServiceV2;

  readonly eventService?: EventServiceV2;
  readonly marketContextService?: AudiMarketContextServiceV2;
}

export const ServiceContext = createContext<ServiceContextState>(
  undefined as unknown as ServiceContextState,
);

export interface ServiceContextProviderProps extends PropsWithChildren {
  readonly featureServices: FeatureServiceDependencies;
}

export const ServiceContextProvider: FC<ServiceContextProviderProps> = ({
  featureServices,
  children,
}) => {
  const authServiceProvider = featureServices['vw:authService'];
  const authService = authServiceProvider.register('myaudi');
  const referenceServiceScopeManager = featureServices['audi-footnote-reference-service'];
  const footnoteReferenceService = referenceServiceScopeManager.getDefaultScopeRefService();

  const state = useMemo<ServiceContextState>(
    () => ({
      authService,
      envConfigService: featureServices['audi:envConfigService'],
      dealerContextService: featureServices['gfa:dealer-context-service'],
      footnoteReferenceService,
      headerStateService: featureServices['audi-header-state-service'],
      layerManager: featureServices['layer-manager'],
      trackingService: featureServices['audi-tracking-service'],

      eventService: featureServices['event-service'],
      marketContextService: featureServices['audi-market-context-service'],
    }),
    [featureServices],
  );

  return <ServiceContext.Provider value={state}>{children}</ServiceContext.Provider>;
};
