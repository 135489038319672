/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import AudiHeaderDataManager from './components/AudiHeader/AudiHeaderDataManager';
import type { AudiHeaderDataManagerProps } from './interfaces/header-components.interfaces';

const FeatureApp: React.FC<AudiHeaderDataManagerProps> = ({
  enablePartnerIdReplacement,
  headerConfigDataUrl,
  ssrHeaderData,
  contentHeadless,
}) => {
  return (
    <AudiHeaderDataManager
      enablePartnerIdReplacement={enablePartnerIdReplacement}
      headerConfigDataUrl={headerConfigDataUrl}
      ssrHeaderData={ssrHeaderData}
      contentHeadless={contentHeadless}
    />
  );
};

export default FeatureApp;
