import { UnsubscribeFn } from '@oneaudi/dealer-context-service';
import { EnumerableFootnote } from '@oneaudi/footnote-reference-service';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { IHeaderAction, createSetActiveItemAction } from '../../actions/header-actions';
import { DealerContext } from '../../context';
import { getDealerData } from '../../graphql/get-dealer';
import { AudiHeaderDataManagerProps, Dealer } from '../../interfaces/header-components.interfaces';
import HeaderResponse from '../../interfaces/header-response.interfaces';
import { HeaderState, headerReducer } from '../../reducers/header-reducer';
import { createUseFootnoteReferenceServiceTextParserHook } from '../../services/hooks';
import { fetchHeaderConfig } from '../../services/navigation-service';
import { cleanNavigationEntries } from '../../utils/clean-navigation-entries';
import { DEALER_CONTEXT_UNSUBSCRIBE, KEY_DEALER_DATA } from '../../utils/globals';
import { isEmpty } from '../../utils/is-empty';
import {
  NormalizeContentConfig,
  normalizeContentHeadless,
} from '../../utils/normalize-falcon-data';
import { replacePartnerId, renderPartnerId } from '../../utils/replace-partner';
import AudiHeader from './AudiHeader';
import { impressionEvent } from '../../utils/tracking';
import { ServiceContext } from '../../context/ServiceContext';
import { ConfigContext } from '../../context/ConfigContext';
import { matchCountryUrlOrigin } from '../../utils/match-url-pattern';

// eslint-disable-next-line max-statements
const AudiHeaderDataManager: React.FC<AudiHeaderDataManagerProps> = ({
  ssrHeaderData,
  headerConfigDataUrl,
  enablePartnerIdReplacement,
  contentHeadless,
}) => {
  const {
    dealerContextService,
    footnoteReferenceService,
    headerStateService,
    marketContextService,
    trackingService,
  } = useContext(ServiceContext);
  const {
    renderDealerContext: renderDealerContextConfig,
    useOneLayer: useOneLayerConfig,
    useUserMenu: useUserMenuConfig,
    disableMyAudiMenu,
    tierOneUrl,
    OneShopEnabled,
  } = useContext(ConfigContext);
  const isOneCms = contentHeadless?.__type === 'aem-headless';
  const [footnotes, setFootnotes] = useState([]);
  const [partnerId, setPartnerId] = useState(undefined);
  const [partnerIdQueryString, setPartnerIdQueryString] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [headerConfig, setHeaderConfig] = useState<HeaderResponse | undefined>(ssrHeaderData);
  const [renderDealerContext, setRenderDealerContext] =
    useState<boolean>(renderDealerContextConfig);
  const [useOneLayer, setUseOneLayer] = useState(useOneLayerConfig);
  const [useUserMenu, setUserMenu] = useState(useUserMenuConfig);
  const [displayUserMenu, setDisplayUserMenu] = useState<boolean>(useUserMenu || false);
  const [dealerData, setDealerData] = useState<Dealer | undefined>(undefined);

  const useFootnoteReferenceServiceTextParserHook = createUseFootnoteReferenceServiceTextParserHook(
    {
      footnotes,
    },
  );

  useEffect(() => {
    (async (): Promise<void> => {
      if (typeof marketContextService !== 'undefined') {
        /* this is needed in order to support both CMS variants. In legacy CMS we are using
         * the ENV Scope (via Market Context Service) in order to retrieve the setting. In
         * headless oneCMS we will need to fetch the setting via Feature App Config instead.
         * */

        await marketContextService
          .initMarketContext()
          .then(() => {
            setUseOneLayer(marketContextService.hasEnvScope('ONEHEADER_ONE_LAYER'));
            if (!isOneCms) {
              setUserMenu(marketContextService.hasEnvScope('ENABLE_USER_MENU'));
              setRenderDealerContext(marketContextService.hasEnvScope('ENABLE_DEALER_CONTEXT'));
            }
          })
          .catch((e) => {
            // when market context service throws an error in headless ignore.
            // fa configuration will be used instead,
            // otherwise the error should be logged as expected
            if (!isOneCms) {
              // eslint-disable-next-line no-console
              console.error('fa-nemo-header', e);
            }
          });
      }
    })();
  }, [marketContextService, isOneCms]);

  // logic to disable user menu on tier 3 markets
  useEffect(() => {
    if (disableMyAudiMenu) {
      if (useUserMenu && !matchCountryUrlOrigin(window.location.origin, tierOneUrl || '')) {
        setDisplayUserMenu(false);
      }
    }
  }, [disableMyAudiMenu, tierOneUrl, useUserMenu]);

  useEffect(() => {
    let unsubscribeDealerContext: UnsubscribeFn;

    if (renderDealerContext) {
      unsubscribeDealerContext = dealerContextService.subscribe((dealerContext) => {
        if (dealerContext) {
          const dealerIdFromContext = `${dealerContext?.market}${dealerContext?.brand}${dealerContext?.id}`;
          const thePartnerId = renderPartnerId(dealerContext?.id, dealerContext?.market, isOneCms);
          setPartnerIdQueryString(thePartnerId);
          const store = sessionStorage.getItem(KEY_DEALER_DATA);
          if (store !== DEALER_CONTEXT_UNSUBSCRIBE) {
            const storageData = JSON.parse(store || '{}');
            if (storageData?.dealerId !== dealerIdFromContext) {
              getDealerData(process.env.GRAPHQL_DEALER_ENDPOINT, dealerIdFromContext)
                .then((data) => {
                  if (data) {
                    const { dealerById } = data;
                    setDealerData(dealerById);
                    sessionStorage.setItem(KEY_DEALER_DATA, JSON.stringify(dealerById));
                  }
                })
                .catch(() => {
                  if (!isEmpty(storageData)) {
                    setDealerData(storageData);
                  }
                  // eslint-disable-next-line no-console
                  console.error(`[fa-nemo-header] dealer id ${dealerIdFromContext} not found`);
                });
            } else if (!isEmpty(storageData)) {
              setDealerData(storageData);
            }
          }
        } else setPartnerIdQueryString('');
      });
    }
    return (): void => {
      if (renderDealerContext) {
        unsubscribeDealerContext();
      }
    };
  }, [dealerContextService, renderDealerContext]);

  useEffect(() => {
    const store = sessionStorage.getItem(KEY_DEALER_DATA);
    if (store === DEALER_CONTEXT_UNSUBSCRIBE) {
      const dealerStore = dealerContextService.getSelectedDealer();
      if (dealerStore) {
        dealerContextService.setSelectedDealer(undefined); // remove dealer-context from session storage
        sessionStorage.removeItem(KEY_DEALER_DATA); // remove fa-header-dealer-data from session storage
      }
    }
  }, [dealerData]);

  useEffect(() => {
    if (window && window.location.href.indexOf('debug=true') !== -1) {
      headerStateService.setDebugMode(true);
    }
    if (footnoteReferenceService) {
      footnoteReferenceService.registerCallback((_footnotes: EnumerableFootnote[]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setFootnotes(_footnotes);
      });
    }

    const handleWindowLoad = () => {
      trackingService?.track(impressionEvent('Impression'));
    };

    window.addEventListener('load', handleWindowLoad);

    return (): void => {
      if (window) {
        if (window.location.href.indexOf('debug=true') !== -1) {
          headerStateService.setDebugMode(true);
        } else {
          headerStateService.setDebugMode(false);
        }
      }
      if (footnoteReferenceService) {
        footnoteReferenceService.removeFootnoteReferences();
      }

      window.removeEventListener('load', handleWindowLoad);
    };
  }, []);

  useEffect(() => {
    if (!isOneCms) {
      // always load clientside again, to be sure, not to work with a cached version from SSR
      fetchHeaderConfig(headerConfigDataUrl)
        .then((response) => {
          setHeaderConfig(
            cleanNavigationEntries(
              enablePartnerIdReplacement ? replacePartnerId(response, partnerId) : response,
            ),
          );
        })
        // eslint-disable-next-line no-console
        .catch((headerConfigError) => console.error(headerConfigError));
    } else {
      const normalizeConfig: NormalizeContentConfig = { tierOneUrl, OneShopEnabled };
      setHeaderConfig(
        enablePartnerIdReplacement
          ? replacePartnerId(
              normalizeContentHeadless(contentHeadless, normalizeConfig, partnerIdQueryString),
              partnerId,
            )
          : normalizeContentHeadless(contentHeadless, normalizeConfig, partnerIdQueryString),
      );
    }
    setIsLoading(false);
  }, [headerConfigDataUrl, ssrHeaderData, partnerId, partnerIdQueryString]);

  const [headerState, headerDispatch] = useReducer<React.Reducer<HeaderState, IHeaderAction>>(
    headerReducer,
    {
      activeItem: {
        anchor: null,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      },
    },
  );

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      !enablePartnerIdReplacement ||
      !(window.microkernel && window.microkernel.stateRegistry)
    ) {
      return (): void => {
        // Do nothing
      };
    }

    const partnerCallback = (state: React.SetStateAction<undefined>): void => {
      setPartnerId(state);
    };

    window.microkernel.stateRegistry.subscribeToStore('dbadDealerStore', partnerCallback);

    return (): void => {
      window.microkernel.stateRegistry.unsubscribeFromStore('dbadDealerStore', partnerCallback);
    };
  }, [enablePartnerIdReplacement, setPartnerId]);

  const removeDealer = useCallback((): void => {
    setDealerData(undefined);
    sessionStorage.setItem(KEY_DEALER_DATA, DEALER_CONTEXT_UNSUBSCRIBE);
    headerDispatch(
      createSetActiveItemAction({
        anchor: null,
        headerStateService,
        index: -1,
        showSearch: false,
        showDealer: false,
        showLoginFlyout: false,
      }),
    );
  }, [setDealerData]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DealerContext.Provider value={{ renderDealerContext, dealerData, removeDealer }}>
      <AudiHeader
        headerConfig={headerConfig}
        headerDispatch={headerDispatch}
        headerState={headerState}
        isLoading={isLoading}
        useFootnoteReferenceServiceTextParserHook={useFootnoteReferenceServiceTextParserHook}
        useOneLayer={useOneLayer}
        useUserMenu={displayUserMenu}
        isOneCms={isOneCms}
        data-tracking-exclude
      />
    </DealerContext.Provider>
  );
};

export default AudiHeaderDataManager;
