/**
 * This is the entry point for Feature Hub App integration
 */

import { ThemeProvider } from '@audi/audi-ui-react';
import type { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import type { Logger } from '@feature-hub/logger';
import { ReactFeatureApp } from '@feature-hub/react';
import type { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { AuthServiceProviderV3 } from '@oneaudi/audi-auth-service';
import type { EnvConfigServiceV1 } from '@oneaudi/audi-env-config-service';
import type { EventServiceV2 as EventService } from '@oneaudi/audi-event-service';
import type { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import type { DealerContextServiceV1 } from '@oneaudi/dealer-context-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import type { AudiHeaderStateServiceInterfaceV2 } from '@oneaudi/header-state-service';
import I18nContextComponent from '@oneaudi/i18n-context';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import type { AudiMarketContextServiceV2 } from '@oneaudi/market-context-service';
import type { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import React from 'react';
import FeatureApp from './FeatureApp';
import { AudiHeaderConfig } from './interfaces/header-components.interfaces';
import type HeaderResponse from './interfaces/header-response.interfaces';
import { fetchHeaderConfig } from './services/navigation-service';
import { cleanNavigationEntries } from './utils/clean-navigation-entries';
import {
  ContentHeadless,
  FalconConfig,
  normalizeConfig,
  normalizeContentHeadless,
} from './utils/normalize-falcon-data';
import { appReady } from './utils/tracking';
import { ServiceContextProvider } from './context/ServiceContext';
import { ConfigContextProvider } from './context/ConfigContext';
// Type to work with the microkenrel that maybe is available globaly
declare global {
  interface Window {
    microkernel: {
      stateRegistry: {
        // eslint-disable-next-line max-len
        subscribeToStore: (
          name: string,
          callback: (state: React.SetStateAction<undefined>) => void,
        ) => void;
        // eslint-disable-next-line max-len
        unsubscribeFromStore: (
          name: string,
          callback: (state: React.SetStateAction<undefined>) => void,
        ) => void;
      };
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    SETUPS: {
      get(arg0: string): string;
    };
  }
}

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi:envConfigService': EnvConfigServiceV1;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-header-state-service': AudiHeaderStateServiceInterfaceV2;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'gfa:dealer-context-service': DealerContextServiceV1;
  readonly 'layer-manager': LayerManagerV25;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'vw:authService': AuthServiceProviderV3;

  readonly 'audi-market-context-service'?: AudiMarketContextServiceV2;
  readonly 's2:logger'?: Logger;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1 | undefined;

  readonly 'event-service'?: EventService;
}

type FeatureHubAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  FeatureServiceDependencies,
  AudiHeaderConfig | FalconConfig
>;

const featureAppDefinition: FeatureHubAppDefinitionType = {
  create: ({ featureServices, config: rawConfig, featureAppId }) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const config = normalizeConfig(rawConfig);
    const headerConfigDataUrl = config?.navigationServiceUrl;
    const enablePartnerIdReplacement = config?.enablePartnerIdReplacement || false;
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const contentService = featureServices['audi-content-service'];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const contentHeadless: ContentHeadless = contentService.getContent();
    // local headless testing
    // const contentHeadless: any = rawConfig;
    const trackingService = featureServices['audi-tracking-service'];
    if (trackingService) {
      trackingService.featureAppName = 'fa-nemo-header';
      trackingService.track(appReady);
    }

    let ssrHeaderData: HeaderResponse = {} as HeaderResponse;

    if (contentHeadless?.__type !== 'aem-headless') {
      // async ssr manager only useful in legacy AEM
      if (asyncSsrManager && headerConfigDataUrl) {
        if (typeof serializedStateManager !== 'undefined') {
          serializedStateManager.register(() => JSON.stringify(ssrHeaderData));
        }
        asyncSsrManager.scheduleRerender(
          (async (): Promise<HeaderResponse> => {
            await fetchHeaderConfig(headerConfigDataUrl).then((response) => {
              ssrHeaderData = cleanNavigationEntries(response);
            });
            return ssrHeaderData;
          })(),
        );
      } else {
        const serializedAudiHeaderData = serializedStateManager?.getSerializedState();
        if (serializedAudiHeaderData) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          ssrHeaderData = JSON.parse(serializedAudiHeaderData);
        }
      }
    } else {
      ssrHeaderData = normalizeContentHeadless(contentHeadless, {
        OneShopEnabled: config?.OneShopEnabled,
        tierOneUrl: config?.tierOneUrl,
      });
    }

    return {
      render: (): React.ReactNode => (
        <ThemeProvider>
          <ConfigContextProvider config={config} featureAppId={featureAppId}>
            <ServiceContextProvider featureServices={featureServices}>
              <I18nContextComponent
                featureServices={featureServices}
                i18nData={featureServices['dbad:audi-i18n-service']}
                scopes={['nemo.common', 'fa.audi-basket', 'nemo.ui.dealer-context']}
              >
                <FeatureApp
                  enablePartnerIdReplacement={enablePartnerIdReplacement}
                  headerConfigDataUrl={headerConfigDataUrl}
                  ssrHeaderData={ssrHeaderData}
                  contentHeadless={contentHeadless}
                />
              </I18nContextComponent>
            </ServiceContextProvider>
          </ConfigContextProvider>
        </ThemeProvider>
      ),
    };
  },
  dependencies: {
    externals: {
      '@audi/audi-ui-react-v2': '^3.7.0',
      '@feature-hub/react': '^3.5.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '*',
      '@oneaudi/onegraph-client': '*',
    },
    featureServices: {
      'audi-footnote-reference-service': '3.0.0',
      'audi-header-state-service': '2.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'layer-manager': '2.4.0',
      'locale-service': '^1.0.0',
      'vw:authService': '^2.3.0',
      'audi:envConfigService': '^1.0.0',
      'audi-content-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-market-context-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'gfa:dealer-context-service': '1.0.0',
      'audi-tracking-service': '^2.0.0',
      'event-service': '^2.0.1',
    },
  },
};

export default featureAppDefinition;
