import { createContext } from 'react';
import { Dealer } from '../interfaces/header-components.interfaces';

interface DealerContextInterface {
  readonly renderDealerContext: boolean;
  readonly dealerData?: Dealer;
  readonly removeDealer: () => void;
}

export const DealerContext = createContext<DealerContextInterface>({} as DealerContextInterface);
