import type { AudiHeaderConfig } from '../interfaces/header-components.interfaces';

export interface MiniCartConfiguration {
  readonly miniCartFeatureAppBaseUrl: string;
  readonly miniCartFeatureAppSrc: string;
  readonly oneShopUbffUrl: string;
}

export const getMiniCartConfiguration = (config?: AudiHeaderConfig): MiniCartConfiguration => {
  let miniCartFeatureAppBaseUrl =
    'https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/5.3.0';
  let miniCartFeatureAppSrc = 'mini-cart.js';
  let oneShopUbffUrl = 'https://www.audi.de/oneshop/proxy/ubff';

  const { miniCartFeatureAppVersion, miniCartFeatureAppSource, ubffEndPointUrl } = config ?? {};

  if (miniCartFeatureAppVersion) {
    const lastMajorMiniCartFeatureAppVersionInFam = 5;
    const versionParts = /^(\d+)\.\d+\.\d+$/.exec(miniCartFeatureAppVersion);

    let version = lastMajorMiniCartFeatureAppVersionInFam;
    if (versionParts) {
      version = parseInt(versionParts[1], 10);
    }

    if (version <= lastMajorMiniCartFeatureAppVersionInFam) {
      miniCartFeatureAppBaseUrl = `https://featureapps.audi.com/audi-feature-app-oneshop-frontend-mini-cart/${miniCartFeatureAppVersion}`;
      miniCartFeatureAppSrc = 'mini-cart.js';
    }
  }

  if (miniCartFeatureAppSource) {
    const newMiniCartFeatureAppSrc = 'mini-cart/mini-cart.js';
    const miniCartHostNameRegex = /^fa-oneshop-frontend\.cdn\.(dev|prod).collab.apps.one.audi$/;
    const parsedMiniCartFeatureAppSource = new URL(miniCartFeatureAppSource);
    if (
      miniCartHostNameRegex.test(parsedMiniCartFeatureAppSource.hostname) &&
      parsedMiniCartFeatureAppSource.pathname.endsWith(newMiniCartFeatureAppSrc)
    ) {
      [miniCartFeatureAppBaseUrl] = miniCartFeatureAppSource.split(`/${newMiniCartFeatureAppSrc}`);
      miniCartFeatureAppSrc = newMiniCartFeatureAppSrc;
    }
  }

  if (ubffEndPointUrl) {
    oneShopUbffUrl = ubffEndPointUrl;
  }

  return {
    miniCartFeatureAppBaseUrl,
    miniCartFeatureAppSrc,
    oneShopUbffUrl,
  };
};
