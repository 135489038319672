export const userMenuContent = {
  benefitHeadline: {
    defaultMessage: 'Welcome to myAudi',
    id: 'fa.header.usermenu.benefit.content.headline',
  },
  benefitSubHeadline: {
    defaultMessage: 'Login and enjoy the digital world of Audi, with all it’s benefits::',
    id: 'fa.header.usermenu.benefit.content.subheadline',
  },
  benefitContent: {
    defaultMessage:
      '<ul><li>Equipment details, services and management of vehicles</li><li>Flexible booking of special equipment</li><li>Current map update with all blocks</li></ul>',
    id: 'fa.header.usermenu.benefit.content.richtext',
  },
  benefitLogin: {
    defaultMessage: 'Login',
    id: 'fa.header.usermenu.benefit.signin.label',
  },
  benefitMore: {
    defaultMessage: 'Registration',
    id: 'fa.header.usermenu.benefit.register.label',
  },
  benefitMarketUrl: {
    defaultMessage: 'Learn More',
    id: 'fa.header.usermenu.benefit.dealer.url',
  },
  benefitHome: {
    defaultMessage: 'myAudi Home',
    id: 'fa.header.usermenu.benefit.home.label',
  },
  benefitMyAudi: {
    defaultMessage: 'myAudi Account',
    id: 'fa.header.usermenu.benefit.myAudiAccount.label',
  },
  benefitLogout: {
    defaultMessage: 'Logout',
    id: 'fa.header.usermenu.benefit.logout.label',
  },
  flyoutLabel: {
    defaultMessage: 'Open myAudi User Menu',
    id: 'fa.header.usermenu.open.flyout.aria.label',
  },
};
