import { ContentFragment } from '@oneaudi/falcon-tools';
import HeaderResponse, {
  MenuItem,
  Search as LegacySearch,
  Link as LegacyLink,
  Teaser as LegacyTeaser,
  LoginLink,
  Suppliers,
} from '../interfaces/header-response.interfaces';
import { AudiHeaderConfig } from '../interfaces/header-components.interfaces';
import { matchCountryUrlOrigin } from './match-url-pattern';
import { relativeToAbsoluteURL } from './append-relative-url';

export type FalconConfig = {
  // same identifier as for the content
  __type?: 'aem-headless';

  // Note: this is not required by default. It may only be required if you
  // override the options, so that the feature app config is required on your root CFM
  config?: Partial<ContentFragment> & {
    fields: AudiHeaderConfig;
  };
};

type Login = {
  fields: LoginLink;
};

type Search = {
  fields: LegacySearch;
};

type Link = {
  fields: {
    IsAudiSport: boolean;
    Text: string;
    Url: string;
    Type: string;
    Target: string;
  };
};

type TeserLink = {
  fields: {
    Text: string;
    Url: string;
    Type: string;
    Target: string;
  };
};

type Teaser = {
  fields: {
    Image: {
      path: string;
      assetId: string;
      created: string;
      height: number;
      mimeType: string;
      sizeInBytes: number;
      status: string;
      width: number;
    };
    ImageAltText: string;
    Link: TeserLink;
  };
};

export type MainNavigationType = {
  fields: {
    Link: Link;
    SubNavigation: Link[];
    Teaser: Teaser;
  };
};

export type ContentHeadless = {
  __type: string;
  fields: {
    MenuLabel: string;
    LogoText: string;
    LogoUrl: string;
    Search?: Search;
    MainNavigation: MainNavigationType[];
    Login?: Login;
    SupplierName?: string;
    SupplierLinkLabel?: string;
    SupplierLinkUrl?: string;
  };
};

export function normalizeConfig(
  config?: AudiHeaderConfig | FalconConfig,
): AudiHeaderConfig | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config && config?.__type === 'aem-headless') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return config?.config?.fields as AudiHeaderConfig;
  }
  return config as AudiHeaderConfig;
}
function isAbsoluteURL(url: string) {
  const pattern = /(http|https|www)/i;
  const isAbsoluteUrl = pattern.test(url);
  return isAbsoluteUrl;
}

const appendPartnerID = (
  urlToAppend: string,
  partnerIdQueryString: string,
  config?: AudiHeaderConfig,
) => {
  const url = !isAbsoluteURL(urlToAppend)
    ? `${window.location.protocol}//${urlToAppend}`
    : urlToAppend;

  if (matchCountryUrlOrigin(url, config?.tierOneUrl || '')) {
    const urlObj = new URL(url);
    urlObj.searchParams.append('partner_id', partnerIdQueryString.toString());
    return urlObj.toString();
  }

  return urlToAppend;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface NormalizeContentConfig
  extends Pick<AudiHeaderConfig, 'tierOneUrl' | 'OneShopEnabled'> {}

export function normalizeContentHeadless(
  headless: ContentHeadless,
  config?: NormalizeContentConfig,
  partnerIdQueryString?: string | undefined,
): HeaderResponse {
  const MainNavigation: MenuItem[] = [];
  headless.fields.MainNavigation.forEach((item) => {
    const SubNavigation: LegacyLink[] = [];
    item.fields.SubNavigation?.forEach((sub) => {
      let url = relativeToAbsoluteURL(sub.fields?.Url, config?.tierOneUrl || '');
      if (partnerIdQueryString) {
        url = appendPartnerID(url, partnerIdQueryString, config);
      }
      const subLink = {
        Text: sub.fields?.Text,
        Type: sub.fields?.Type,
        Url: url,
        Target: sub.fields?.Target,
        IsAudiSport: sub.fields?.IsAudiSport,
      };
      SubNavigation.push(subLink);
    });
    let teaserLink = relativeToAbsoluteURL(
      item.fields.Teaser?.fields.Link?.fields.Url,
      config?.tierOneUrl || '',
    );
    if (partnerIdQueryString) {
      teaserLink = appendPartnerID(teaserLink, partnerIdQueryString, config);
    }
    const Teaser: LegacyTeaser = {
      Image: {
        Alt: `${item.fields.Teaser?.fields.ImageAltText}`,
        Height: `${item.fields.Teaser?.fields.Image.height}`,
        Width: `${item.fields.Teaser?.fields.Image.width}`,
        Src: `${item.fields.Teaser?.fields.Image.path}`,
      },
      Link: {
        ...item.fields.Teaser?.fields.Link.fields,
        Url: teaserLink,
      },
    };
    let mainUrl = relativeToAbsoluteURL(item?.fields?.Link?.fields?.Url, config?.tierOneUrl || '');
    if (partnerIdQueryString) {
      mainUrl = appendPartnerID(mainUrl, partnerIdQueryString, config);
    }
    const menuLink = {
      Text: item?.fields?.Link?.fields?.Text,
      Type: item?.fields?.Link?.fields?.Type,
      Url: mainUrl,
      Target: item?.fields?.Link?.fields?.Target,
      IsAudiSport: item?.fields?.Link?.fields?.IsAudiSport,
    };
    const menuItem: MenuItem = {
      Link: menuLink || undefined,
      SubNavigation,
      Teaser: Teaser?.Image?.Src !== 'undefined' ? Teaser : undefined,
    };
    MainNavigation.push(menuItem);
  });
  const suppliers: Suppliers[] = [
    {
      Name: headless.fields.SupplierName || undefined,
      LinkUrl: headless.fields.SupplierLinkUrl || undefined,
      LinkLabel: headless.fields.SupplierLinkLabel || undefined,
    },
  ];
  return {
    MenuLabel: headless.fields.MenuLabel,
    Logo: {
      Text: headless.fields.LogoText,
      Url: headless.fields.LogoUrl,
      Type: 'default',
      Target: '_self',
    },
    Login: headless.fields.Login?.fields || undefined,
    Search: headless.fields.Search?.fields || undefined,
    MainNavigation,
    OneShopEnabled: config?.OneShopEnabled || false,
    Suppliers: suppliers,
  };
}
