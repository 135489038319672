import type { FC, PropsWithChildren } from 'react';
import React, { createContext, useMemo } from 'react';
import type { AudiHeaderConfig } from '../interfaces/header-components.interfaces';
import { getMiniCartConfiguration, type MiniCartConfiguration } from './getMiniCartConfiguration';
import { NormalizeContentConfig } from '../utils/normalize-falcon-data';

export interface ConfigContextState extends MiniCartConfiguration, NormalizeContentConfig {
  readonly featureAppId: string;
  readonly loginFeatureAppUrl: string;
  readonly searchInputFeatureAppUrl: string;
  readonly searchResultsFeatureAppUrl: string;
  readonly renderDealerContext: boolean;
  readonly useOneLayer: boolean;
  readonly useUserMenu: boolean;
  readonly disableMyAudiMenu: boolean;
}

export const ConfigContext = createContext<ConfigContextState>(
  undefined as unknown as ConfigContextState,
);

export interface ConfigContextProviderProps extends PropsWithChildren {
  readonly featureAppId: string;
  readonly config?: AudiHeaderConfig;
}

export const ConfigContextProvider: FC<ConfigContextProviderProps> = ({
  config,
  featureAppId,
  children,
}) => {
  const state = useMemo<ConfigContextState>(() => {
    const {
      usermenuFeatureAppVersion = '9',
      searchFeatureAppVersion = '6.1.4',
      searchResultsFeatureAppVersion = '6.1.17',
      renderDealerContext = false,
      useOneLayer = false,
      useUserMenu = false,
      disableMyAudiMenu = false,
      OneShopEnabled,
      tierOneUrl,
    } = config ?? {};

    return {
      featureAppId,
      loginFeatureAppUrl: `https://featureapps.audi.com/audi-feature-app-user-menu/${usermenuFeatureAppVersion}/app.js`,
      searchInputFeatureAppUrl: `https://fa-search-input.cdn.prod.arcade.apps.one.audi/${searchFeatureAppVersion}/fh/app.js`,
      searchResultsFeatureAppUrl: `https://fa-search-results.cdn.prod.arcade.apps.one.audi/${searchResultsFeatureAppVersion}/fh/app.js`,
      renderDealerContext,
      useOneLayer,
      useUserMenu,
      disableMyAudiMenu,
      OneShopEnabled,
      tierOneUrl,
      ...getMiniCartConfiguration(config),
    };
  }, [featureAppId, config]);

  return <ConfigContext.Provider value={state}>{children}</ConfigContext.Provider>;
};
