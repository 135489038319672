import { useContext, useEffect, useState } from 'react';

import type { IdkRegion } from '../../utils/idk';
import { ServiceContext } from '../../context/ServiceContext';

export interface ConsentServiceConfig {
  baseUrl: string;
}

export type ConsentServiceConfigMapping = {
  [region in IdkRegion]?: ConsentServiceConfig;
};

export interface CustomerProfileServiceConfig {
  baseUrl: string;
}

export type CustomerProfileServiceConfigMapping = {
  [region in IdkRegion]?: CustomerProfileServiceConfig;
};

export type IdkIssuerRegionMapping = {
  [region in IdkRegion]?: string;
};

export interface IdkConfig {
  consentService: ConsentServiceConfigMapping;
  customerProfileService: CustomerProfileServiceConfigMapping;
  issuerRegionMapping: IdkIssuerRegionMapping;
}

export interface EnvConfig {
  idk?: IdkConfig;
}

export function useEnvConfig(): EnvConfig {
  const { envConfigService } = useContext(ServiceContext);
  const [idk, setIdk] = useState<IdkConfig>();
  useEffect(() => {
    const loadConfigs = async () => {
      if (envConfigService) {
        // eslint-disable-next-line no-useless-catch
        try {
          const idkResp = await envConfigService.getConfiguration('idk');
          setIdk(idkResp as IdkConfig);
        } catch (error) {
          throw error;
        }
      }
    };

    loadConfigs();
  }, [envConfigService]);

  return { idk };
}
