import React, { useContext } from 'react';
import { Text, Button, PopoverTrigger, PopoverBody } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import AppLoaderErrorBoundary from '../AppLoaderErrorBoundary/AppLoaderErrorBoundary';
import { AudiWishlistProps } from '../../interfaces/header-components.interfaces';
import { wishlist } from '../../i18n/wishlist';
import { HeaderStyledLogin, StyledPopoverContainer } from '../AudiHeader/AudiHeaderStyles';
import { logoutClickOnWishlist, loginClickOnWishlist } from '../../utils/tracking';
import { useAuth } from '../../hooks/use-auth';
import { ServiceContext } from '../../context/ServiceContext';

const AudiWishlist: React.FC<AudiWishlistProps> = ({
  headerNavigationItemsAmount,
  isOpen,
  useUserMenu,
  wishlistUrl,
  homeUrl,
  onClickWishlist,
}) => {
  const { authService, trackingService } = useContext(ServiceContext);
  const wishlistHeadlineLabel = useI18n(wishlist.wishlistHeadline);
  const wishlistSubHeadlineLabel = useI18n(wishlist.wishlistSubHeadline);
  const wishlistLoginLabel = useI18n(wishlist.wishlistLogin);
  const wishlistIconAriaLabel = useI18n(wishlist.wishlistIconAriaLabel);
  const { isAuthenticated } = useAuth();

  const handleLoginClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const targetUrl = wishlistUrl !== 'undefined' && wishlistUrl !== '' ? wishlistUrl : homeUrl;
    authService.login(undefined, typeof window !== 'undefined' ? targetUrl : homeUrl);
  };

  const trackLogoutClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const dynamicClickWishlist = logoutClickOnWishlist();

    trackingService.track(dynamicClickWishlist);
  };

  const trackLoginClick = (event: React.MouseEvent) => {
    event.preventDefault();

    const targetUrl =
      wishlistUrl !== 'undefined' && wishlistUrl !== ''
        ? wishlistUrl
        : 'https://pre-www.audi.de/de/brand/de/wishlist.html';
    const dynamicClickWishlist = loginClickOnWishlist(targetUrl);

    trackingService.track(dynamicClickWishlist);
  };

  const trackingEventClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (isAuthenticated) {
      trackLoginClick(event);
      handleLoginClick(event);
    } else {
      trackLogoutClick(event);
    }
    onClickWishlist();
  };

  const renderLogOutState = (): JSX.Element => {
    return (
      <PopoverTrigger placement="bottom right" tip>
        {(triggerProps) => (
          <>
            <Button
              variant="icon-tertiary"
              icon="favorite"
              size="small"
              type="button"
              aria-label={wishlistIconAriaLabel}
              {...triggerProps}
              onClick={(e) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (triggerProps.onClick) {
                  // open popover from triggerProps
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  triggerProps.onClick(e);
                }
                // initiate tracking
                trackingEventClick(e);
              }}
            />
            <StyledPopoverContainer>
              <PopoverBody>
                <Text as="h4" variant="order4" spaceStackEnd="s">
                  {wishlistHeadlineLabel}
                </Text>
                <Text as="p" variant="copy1" spaceStackEnd="xl">
                  {wishlistSubHeadlineLabel}
                </Text>

                <Button
                  aria-label="myAudi Login"
                  onClick={handleLoginClick}
                  variant="primary"
                  stretch
                >
                  {wishlistLoginLabel}
                </Button>
              </PopoverBody>
            </StyledPopoverContainer>
          </>
        )}
      </PopoverTrigger>
    );
  };

  const renderLogInState = () => {
    return (
      <Button
        variant="icon-tertiary"
        icon="favorite"
        size="small"
        type="button"
        onClick={trackingEventClick}
      />
    );
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AppLoaderErrorBoundary>
      <HeaderStyledLogin headerNavigationItemsAmount={headerNavigationItemsAmount} isOpen={isOpen}>
        {
          // eslint-disable-next-line
          wishlistUrl && useUserMenu
            ? isAuthenticated
              ? renderLogInState()
              : renderLogOutState()
            : null
        }
      </HeaderStyledLogin>
    </AppLoaderErrorBoundary>
  );
};

export default AudiWishlist;
