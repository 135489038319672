import { AudiHeaderConfig } from '../interfaces/header-components.interfaces';
import { matchCountryUrlOrigin } from './match-url-pattern';

export const appendPartnerID = (
  urlToAppend: string,
  partnerIdQueryString: string,
  config?: AudiHeaderConfig,
) => {
  const pattern = /(http|https|www)/i;
  const isAbsoluteUrl = pattern.test(urlToAppend);
  const url = !isAbsoluteUrl ? `${window?.location.protocol}//${urlToAppend}` : urlToAppend;

  if (matchCountryUrlOrigin(url, config?.tierOneUrl || '')) {
    const urlObj = new URL(url);
    urlObj.searchParams.append('partner_id', partnerIdQueryString.toString());
    return urlObj.toString();
  }

  return urlToAppend;
};

export function queryStringExists(param: string | null | undefined) {
  if (param === null || param === undefined) {
    return false;
  }
  const urlParams =
    typeof window !== 'undefined' ? new URLSearchParams(window?.location?.search) : null;
  return param ? urlParams?.has(param) : false;
}
