import { useContext, useEffect, useState } from 'react';

import { ServiceContext } from '../../context/ServiceContext';

export function useAuth(): {
  accessToken: string;
  isAuthenticated: boolean;
} {
  const { authService } = useContext(ServiceContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState<string>('');

  useEffect(() => {
    const getAuthStatus = async () => {
      const response = await authService.isAuthenticated();
      setIsAuthenticated(response);
    };
    getAuthStatus();
  }, [authService]);

  useEffect(() => {
    const getToken = async () => {
      const respAccessToken = await authService.getAccessToken();
      setAccessToken(respAccessToken);
    };

    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);

  return {
    isAuthenticated,
    accessToken,
  };
}
